import React from 'react';
import { cn } from '@/lib/utils';
import { useTranslation } from '@/lib/i18n';

import ProfileBadge from '../profile-badge/ProfileBadge';
import AuthorizationDialog from '../authorization-dialog/AuthorizationDialog';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User } from '@expanzi/api-types';
import { Button } from '@expanzi/ui-library';

export type HeaderProps = {
    referral?: string;
    className?: string;
    user: User | null;
};

const UserArea: React.FC<HeaderProps> = (props) => {
    const { referral, className, user } = props;
    const { t } = useTranslation();
    return (
        <div className={cn(className)}>
            {user ? (
                <ProfileBadge user={user} />
            ) : (
                <AuthorizationDialog dealerId={referral}>
                    <Button
                        variant="outline"
                        size="md"
                        rounded
                        className="w-full border-white text-white hover:bg-white"
                    >
                        {t('login.login')}
                    </Button>
                </AuthorizationDialog>
            )}
        </div>
    );
};

export default UserArea;
